@mixin min($value) {
    @media screen and (min-width: $value) {
        @content;
    }
}

@mixin max($value) {
    @media screen and (max-width: $value) {
        @content;
    }
}

@mixin minmax($value-min, $value-max) {
    @media screen and (min-width: $value-min) and (max-width: $value-max) {
        @content;
    }
}

@mixin container {
    max-width: 1400px;
    margin: 0 auto;
}


@mixin rounded {
    border-radius: 10px;
}
