*,
::before,
::after {
    box-sizing: border-box;
}

nav {
    display: unset;
}

.block {
    max-width: 1700px;
    margin: 0 auto;
    &--grey {
        background-color: $light-grey;
    }
    &--white {
        background-color: #ffffff;
    }
    &--purple {
        background-color: $secondary-color;
    }     

    &__content {
        @include container;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        @include min($breakpoint-md) {
            padding-left: 5rem;
            padding-right: 5rem;
        }
        @include min($breakpoint-xl) {
            padding-left: 8.125rem;
            padding-right: 8.125rem;
        }

        &--wide {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.page-section {
    display: flex;
    flex-direction: column;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
