.nav-wrapper {
    background-color: #ffffff;
    position: sticky;
    top: 0;
    z-index: 100;
}

.nav {
    padding: 1rem 1.5rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    @include container;

    @include min($breakpoint-xl) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    &-logo {
        margin-right: auto;
    }
    &-menu {
        width: 100vw;
        height: 100vh;
        background-color: #ffffff;
        z-index: 1000;
        position: fixed;
        right: -200%;
        top: 72px;
        transition: 0.6s ease;
        padding: 0 0.7rem;

        &.active {
            right: 0;
        }

        @include min($breakpoint-md){
            width: 40vw;
        }

        @include min($breakpoint-lg) {
            padding: 0;
            display: flex;
            align-items: center;
            width: auto;
            height: auto;
            position: unset;
        }

        &__item {
            padding: 0.5rem 0;
            display: flex;
            align-items: center;

            &:first-of-type {
                border-top: $border-thin;
                padding-top: 0;
            }

            &:nth-child(5) {
                padding-bottom: 2rem;
                border-bottom: $border-thin;
                margin-bottom: 1rem;
            }

            @include min($breakpoint-lg) {
                padding: 0;

                &:first-of-type {
                    border-top: none;
                    padding-top: 0;
                }

                &:nth-child(5) {
                    padding-bottom: 0;
                    border-bottom: none;
                    margin-bottom: 0;
                }
            }
        }

        &__link {
            padding: 0.5rem 1rem;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 0.5em;
            height: 100%;
            transition: font-weight 0.2s ease;

            &:hover {
                font-weight: bold;
            }

            &:active {
                color: $primary-color;
                font-weight: bold;
            }

            &:disabled {
                font-weight: 400;
                color: #c8c8c8;
            }
        }

        .hover-fix {
            @include min($breakpoint-lg) {
                display: flex;
                flex-direction: column;
                align-items: center;

                &::before {
                    display: block;
                    content: attr(title);
                    font-weight: bold;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
            }
        }

        &__icon {
            width: 20px;
            fill: $primary-color;
        }

        .button--primary.desktop-only {
            @include max($breakpoint-lg - 1) {
                display: none;
            }
        }
    }
    &-toggle {
        #hamburgerIcon {
            display: block;
        }
        #closeIcon {
            display: none;
        }

        &.active {
            #hamburgerIcon {
                display: none;
            }
            #closeIcon {
                display: block;
            }
        }

        @include min($breakpoint-lg) {
            display: none;
        }
    }
}
