.button {
    font-size: 1.125rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.5rem 1.5rem;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #212121;
    display: block;
    text-align: center;
    cursor: pointer;

    @include min($breakpoint-md) {
        display: inline-block;
    }

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &:active {
        box-shadow: none;
    }

    &:disabled {
        pointer-events: none;
    }

    &--primary {
        background-color: $primary-color;

        &:hover {
            background-color: #ffbb22;
        }

        &:disabled {
            color: #ffffff;
            background-color: #ffd984;
        }
    }

    &--secondary {
        background-color: #ffffff;

        &:hover {
            background-color: #fff8e1;
        }

        &:disabled {
            color: #c8c8c8;
        }
    }

    &--bordered {
        border-color: $primary-color;

        &:hover {
            background-color: #fff8e1;
        }

        &:disabled {
            border-color: #c8c8c8;
            color: #c8c8c8;
        }
    }
}
