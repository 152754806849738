.splide {
  &__list {
    display: flex;
    align-items: center;
  }
  &__pagination {
    bottom: -1.5rem;

    &__page {
      background-color: #625eef;

      &.is-active {
        background-color: #635eef8f;
      }
    }
  }
  &.is-initialized:not(.is-active) {
    .splide__list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
