.header {
    position: relative;
    @include container;
    padding: 2.5rem 1.5rem 2rem;

    @include min($breakpoint-sm) {
        padding: 3rem 2rem 2.5rem;
    }
    @include min($breakpoint-lg) {
        padding: 2rem 0 2.5rem 0;
    }

    &__background {

    background: url("../../../assets/images/header_background.png"),linear-gradient(
        90deg,
        rgba(99, 91, 213, 1) 0%,
        rgba(99, 91, 213, 1) 65%,
        rgba(116, 103, 217, 1) 75%,
        rgba(116, 103, 217, 1) 100%
    );
    background-size: cover;
    position: relative;
    margin-bottom: 1rem;
    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1rem;
        background-color: $primary-color;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
    }
}

    img {
        display: none;

        @include min($breakpoint-md) {
            display: block;
            margin: 0 auto;
        }
    }

    h1.h1 {
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        font-style: italic;

        .title-large{
            font-size: 38px;
            color: $primary-color;
            text-transform: uppercase;

        @include min(375px) {
            font-size: 44px;
        }
    }

        .title-small {
            font-size: 26px;
            font-weight: 700;
            color: white;
        }
    }

    &__content {
        position: relative;
        max-width: 994px;
        margin: 0 auto;

        &-text {

            @include min($breakpoint-md) {
                position: absolute;
                top: 50%;
                left: 5%;
                transform: translateY(-50%);
                padding-right: 15%;
            }
            @include min($breakpoint-lg) {
                left: 10%;
                padding-right: 30%;
            }
        }
}

    &-cta {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.5rem 1rem;

        @include min($breakpoint-sm) {
        flex-direction: row;
        align-items: center;
        margin-top: 2rem;
        }

    }
    &-desktop-hidden {
        @include min($breakpoint-lg){
            display: none;
        }
    }
}
