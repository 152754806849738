.footer {
    background-color: $secondary-color;
    color: #ffffff80;
    max-width: 1700px;
    margin: 0 auto;

    &__content {
        max-width: 48rem;
        padding: 1.5rem 2.5rem 0.625rem 2.5rem;
        margin: auto;

        @include min($breakpoint-md) {
            padding: 2.5rem 4rem 1.5rem 4rem;
        }
        @include min($breakpoint-lg) {
            padding: 2.5rem 0 1.5rem 0;
        }
    }

    &-contacts {
        @include min($breakpoint-md) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__address {
        margin-top: 1.2rem;
    }

    &__email {
        display: flex;
        align-items: center;
        gap: 0 0.5rem;
        margin-top: 2.5rem;

        @include min($breakpoint-md) {
            margin-top: 0.5rem;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        gap: 0 1rem;
        margin-top: 1.375rem;
    }

    &__copy {
        text-align: center;
        margin-top: 2.5rem;
    }

    &__icon {
        width: 1.5rem;
        height: auto;
        fill: #ffffff;
    }
}
