.league-logo {
    height: 4.5rem;
    width: auto;
}

.checbox-icon {
    width: 2rem;
    fill: $primary-color;
}

.grey-icon {
    fill: #c8c8c8;
    width: 66px;
}

.leagues {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: $border-thin;

    @include min($breakpoint-lg) {
        flex-direction: row;
        justify-content: center;
        padding-top: 3.25rem;
        padding-bottom: 2.875rem;
    }

    &__list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(auto, 150px));
        place-items: center;
        justify-content: center;
        gap: 2rem 1rem;
    }

    .h3 {
        text-align: center;
        margin-bottom: 1.5em;
        flex-shrink: 0;

        @include min($breakpoint-lg) {
            margin-bottom: 0;
        }
    }
}

.purpose-mobile {
    @include min($breakpoint-lg) {
        display: none;
    }
    .purpose {
        &-text,
        &-list__item {
            border: $border;
            position: relative;
            margin-bottom: 1rem;

            &::after {
                content: "";
                height: 1rem;
                border-right: $border;
                position: absolute;
                top: calc(100% + 2px);
                left: 50%;
            }
        }

        &-text,
        &-list {
            max-width: 25rem;
            margin-left: auto;
            margin-right: auto;
        }

        &-text {
            padding: 0.5rem 1rem;
            text-align: center;
            border-radius: 5px;
            margin-top: 2rem;
        }

        &-list {
            &__item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 1rem 1.5rem;
                border-radius: 10px;

                &:last-child {
                    margin-bottom: 0;
                    &::after {
                        content: normal;
                    }
                }
            }
        }
    }
}

.purpose-desktop {
    display: none;

    @include min($breakpoint-lg) {
        width: 100%;
        display: grid;
    }

    .purpose {
        &-text {
            padding: 0.5rem 1rem;
            text-align: center;
            border-radius: 5px;
            border: $border;
            margin-bottom: 5.625rem;
            position: relative;
            margin-top: 2rem;
            justify-self: center;

            &::before {
                content: "";
                position: absolute;
                height: 3.75rem;
                border-right: $border;
                top: calc(100% + 2px);
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1rem;
            position: relative;

            &::after {
                content: "";
                display: block;
                position: absolute;
                width: calc(2 / 3 * 100% + 0.5rem + 6px);
                height: 2rem;
                border: $border;
                border-bottom: none;
                border-radius: 10px 10px 0 0;
                bottom: calc(100% - 4px);
                left: 50%;
                transform: translate(-50%, -100%);
            }
        }

        &-list__item {
            display: grid;
            justify-items: center;
            gap: 0.5rem 0;
            padding: 1.25rem;
            border: $border;
            border-radius: 10px;
            flex: 1;
            text-align: center;
        }

        &-list__fake-branch {
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                width: calc(50% + 1rem - 6px);
                height: 2rem;
                border: $border;
                border-bottom: none;
                border-radius: 10px 10px 0 0;
                bottom: calc(100% - 2px);
                left: 50%;
                transform: translateX(-50%);
            }

            ul {
                display: flex;
                height: 100%;
                gap: 1rem;
            }
        }
    }
}

.how-section {
    scroll-margin-top: 80px;
    &-list {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        margin-top: 1.5rem;
        max-width: 25rem;
        margin-left: auto;
        margin-right: auto;

        @include min($breakpoint-lg) {
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 100%;
            margin: 01.5rem 0 0 0;
        }

        &__item {
            flex: 1;
            position: relative;
            flex: 1;

            &::after {
                content: url("../../../assets/images/arrow-down.png");
                position: absolute;
                top: calc(100% + 1rem);
                transform: translateY(-25%);

                @include min($breakpoint-lg) {
                    top: 50%;
                    left: calc(100% + 1rem);
                    transform: translate(-25%, -50%) rotate(-90deg);
                }
            }
        }
        &__item:last-child::after {
            content: normal;
        }
    }
}

.card {
    display: grid;
    place-items: center;
    padding: 1.5rem 2.5rem;
    background-color: #ffffff;
    border-radius: 10px;
    min-height: 9rem;
    text-align: center;

    &.active {
        color: white;
        background-color: $secondary-color;
        pointer-events: none;

        .grey-icon {
            fill: #a9a7fa;
        }
    }
}

.who-section {
    background-color: $light-yellow;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: calc(100% + 3rem);
    overflow: visible;
    position: relative;
    left: -1.5rem;

    @include min($breakpoint-md) {
        width: 100%;
        border-radius: 10px;
        position: static;
    }
}

.who-section__content {
    display: block;
    width: 100%;

    .tabs {
        display: flex;
        overflow: scroll;
        justify-content: space-between;
        margin-top: 2.75rem;
        margin-bottom: 2rem;
        gap: 1rem;

        &__item {
            min-width: 15.9rem;
        }

        &__button {
            width: 100%;

            &:hover {
                font-weight: 700;
            }
        }
    }
    .tab-text {
        margin-bottom: 1.5rem;

        @include min($breakpoint-lg) {
            padding-left: 3.5rem;
            padding-right: 15rem;
        }
    }

    .tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }

    .button--primary {
        width: 100%;

        @include min($breakpoint-sm) {
            width: auto;
            min-width: 17.5rem;
            margin-left: 1.25rem;
        }
    }
}

.check-icon {
    width: 1.25rem;
    fill: $primary-color;
}

.pricing-section {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    scroll-margin-top: 80px;

    .h2 {
        margin-bottom: 2.5rem;

        @include min($breakpoint-md) {
            margin-bottom: 2.625rem;
        }
    }
}
.pricing-table {
    &__item {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1.5rem;
        background-color: #ffffff;
        border-radius: 10px;
        //width: 272px;   uncomment when pricing plan is ready
        max-width: 800px; //remove when pricing plan is ready
        margin: 0 auto; //remove when pricing plan is ready

        @media (min-width: 414px) {
            //width: 362px;   uncomment when pricing plan is ready
        }

        @media (min-width: 640px) {
            padding: 3rem;
        }

        .h3 {
            font-weight: 400;
            margin-bottom: 1.375rem;
        }

        .upper {
            text-transform: uppercase;
        }

        &--selected {
            background-color: $secondary-color;
            color: #ffffff;
            padding-top: 3.375rem;
            padding-bottom: 3.375rem;

            .h3 {
                font-weight: bold;
            }

            .pricing-table__description,
            .pricing-table__price--monthly {
                color: #ffffff;
                opacity: 50%;
            }

            .button--bordered {
                background-color: $primary-color;
            }
        }
    }

    &__price {
        text-transform: uppercase;
        font-size: 2.5rem;
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        &--monthly {
            font-size: 1rem;
            text-transform: initial;
        }
    }

    &__description,
    &__price--monthly {
        color: #909090;
    }

    &__description {
        margin-top: 1rem;
    }

    &__list {
        margin-top: 1rem;
        margin-bottom: 1.75rem;

        &-item {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            @include min($breakpoint-md) {
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }
    }

    .cta-button {
        margin-top: auto;
        align-self: center; //remove when pricing plan is ready
        min-width: 100%;  //remove when pricing plan is ready

        @include min($breakpoint-sm) {
            min-width: 17.5rem; //remove when pricing plan is ready
        }
    }
}
