/* Colors */
$primary-color: #fece38;
$secondary-color: #625eef;
$light-grey: #f6f6f6;
$light-yellow: #fff7de;
$border-grey: #e4e4e4;

$breakpoint-xs: 320px;
$breakpoint-sm: 640px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;
$breakpoint-2xl: 1536px;

$border: 2px solid $border-grey;
$border-thin: 1px solid $border-grey;
