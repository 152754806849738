body {
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    color: #212121;
}

.h2 {
    font-size: 2.5rem;
    font-weight: 700;
}

.h3 {
    font-size: 1.5rem;
    font-weight: 700;
}
